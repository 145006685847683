<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <!--Container 3 -->

    <div style="clear: both; display: block; height: 0px;"></div>

    <!--Container - Popular Resorts -->
    <div class="w-full mx-auto pt-0 bg-white">
      <div
        class="flex w-full  bg-gradient-to-r from-gray-100 via-blue-100 to-green-100 text-left"
      >
        <h1
          class="text-2xl font-bold my-8 max-w-7xl md:mx-auto md:pl-4 mx-auto px-2 sm:px-6"
        >
          Contact Us
        </h1>
      </div>
      <div class="max-w-7xl container mx-auto px-4 sm:px-6 mb-20 mt-20">
        <div class="flex flex-wrap items-center justify-center">
          <div class="mx-2 text-left">
            <p class="my-5 text-justify	">
              <b>Nalfalhu Holidays Private Limited </b><br />
              Ma. Rasmathige, Kenery Magu <br />
              Male', Maldives <br />
              Phone: +960 3000871 <br />
              Mobile: +960 9711757 <br />
              Email: sales@nalafalhuholidays.com <br />
              Website: www.maldivesholidays.com <br />
            </p>
          </div>
          <div
            class="flex flex-wrap items-center justify-center py-8 lg:px-20 px-0"
          >
            <p class="my-5 text-justify	">
              <b>Luis Isma </b><br />
              Sales and Marketing Director <br />
              Nalafalhu Holidays Private Limited. <br />
              Tel: +960 971-1757 <br />
              Email: sales@nalafalhuholidays.com <br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <FooterLayout> </FooterLayout>
  </div>
</template>

<script>
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";

export default {
  name: "ContactPage",
  data() {
    return {};
  },
  mounted() {},
  methods: {},

  computed: {},
  components: {
    HeaderLayout,
    FooterLayout
  }
};
</script>

<style scoped></style>
