<template>
  <nav id="footer" class="bg-gray-800">
    <!-- start container -->
    <div class="container mx-auto p-16 s:p-20 sm:p-20 md:p-20 lg:p-20">
      <div class="flex flex-wrap overflow-hidden sm:-mx-1">
        <div
          class="w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4 pb-6"
        >
          <!-- Column 1 Content -->
          <h4 class="text-blue-300 pb-2">About Maldives Holidays</h4>
          <ul class="nav navbar-nav">
            <li id="navi-2" class="leading-7 text-sm">
              <a class="text-white text-small" href="/about"> About us</a>
            </li>
            <li id="navi-2" class="leading-7 text-sm">
              <a class="text-white text-small" href="#"> Press</a>
            </li>
            <li id="navi-2" class="leading-7 text-sm">
              <a class="text-white text-small" href="/policies">
                Resources and Policies</a
              >
            </li>
            <li id="navi-2" class="leading-7 text-sm">
              <a class="text-white text-small" href="/careers"> Careers</a>
            </li>
            <li id="navi-2" class="leading-7 text-sm">
              <a class="text-white text-small" href="#"> Partners</a>
            </li>
          </ul>
        </div>

        <div
          class="w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4 pb-6"
        >
          <!-- Column 2 Content -->

          <h4 class="text-blue-300 pb-2">Explore</h4>
          <ul class="nav navbar-nav">
            <li id="navi-2" class="leading-7 text-sm">
              <a class="text-white text-small" href="#"> Luxury Resorts</a>
            </li>
            <li id="navi-2" class="leading-7 text-sm">
              <a class="text-white text-small" href="/covidupdates">
                Covid-19 Updates</a
              >
            </li>
            <li id="navi-2" class="leading-7 text-sm">
              <a class="text-white text-small" href="/culture">
                Maldives Culture</a
              >
            </li>
            <li id="navi-2" class="leading-7 text-sm">
              <a class="text-white text-small" href="/guidelines">
                Travel Guide</a
              >
            </li>
            <li id="navi-2" class="leading-7 text-sm">
              <a class="text-white text-small" href="/maldivesvisa">
                Maldives Visa</a
              >
            </li>
          </ul>
        </div>

        <div
          class="w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4 pb-6"
        >
          <!-- Column 3 Content -->
          <h4 class="text-blue-300 pb-2">Do Business With Us</h4>
          <ul class="">
            <li id="navi-2" class="leading-7 text-sm">
              <a class="text-white text-small" href="#">
                Accommodation Owners</a
              >
            </li>
            <li id="navi-2" class="leading-7 text-sm">
              <a class="text-white text-small" href="#"> Business Advantage</a>
            </li>
            <li id="navi-2" class="leading-7 text-sm">
              <a class="text-white text-small" href="#"> Advertise with Us</a>
            </li>
            <li id="navi-2" class="leading-7 text-sm">
              <a class="text-white text-small" href="/affiliates">
                Affiliates & Partners</a
              >
            </li>
          </ul>
        </div>

        <div
          class="w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4 pb-6"
        >
          <!-- Column 4 Content -->

          <h4 class="text-blue-300">Follow Us</h4>
          <div class="flex text-white pt-4">
            <a href="https://www.facebook.com/officialmaldivesholidays"
              ><i class="fab fa-facebook pr-3" style="font-size: 24px"></i
            ></a>
            <a href="https://www.instagram.com/officialmaldivesholidays/"
              ><i class="fab fa-instagram pr-3" style="font-size: 24px"></i
            ></a>
            <a href="https://twitter.com/officialmaldivesholidays"
              ><i class="fab fa-twitter pr-3" style="font-size: 24px"></i
            ></a>
            <a href="https://youtube.com/">
              <i class="fab fa-youtube pr-3" style="font-size: 24px"></i
            ></a>
            <a href="https://www.pinterest.com/maldivesholidays/"
              ><i class="fab fa-pinterest pr-3" style="font-size: 24px"></i
            ></a>
          </div>
          <div class="flex pt-8">
            <img src="/img/maldives-logo.png" width="80px" />
          </div>
        </div>
      </div>

      <!-- Start footer bottom -->

      <div
        class="pt-4 md:flex md:items-center md:justify-center border-t border-gray-700"
      >
        <ul class="mb-4 text-gray-200">
          <li
            class="md:mx-2 md:inline leading-7 text-sm text-white"
            id="footer-navi-2"
          ></li>
          <li class="md:mx-2 md:inline leading-7 text-sm" id="footer-navi-2">
            <a class="text-gray-300 text-small" href="/disclaimer"
              >Disclaimer</a
            >
          </li>
          <li class="md:mx-2 md:inline leading-7 text-sm" id="footer-navi-2">
            <a class="text-gray-300 text-small" href="/cookie">Cookie policy</a>
          </li>
          <li class="md:mx-2 md:inline leading-7 text-sm" id="footer-navi-2">
            <a class="text-gray-300 text-small" href="/privacy">Privacy</a>
          </li>
          <li class="md:mx-2 md:inline leading-7 text-sm" id="footer-navi-2">
            <a class="text-gray-300 text-small" href="/refundpolicy"
              >Refund Policy</a
            >
          </li>
          <li class="md:mx-2 md:inline leading-7 text-sm" id="footer-navi-2">
            <a class="text-gray-300 text-small" href="/privacy">Terms of Use</a>
          </li>
        </ul>
      </div>

      <p><img class="mx-auto" src="/img/nalafalhulogo.png" width="180px" /></p>
      <p class="flex justify-center text-gray-300 text-sm">
        © 2020 - 2021 Nalafalhu Holidays Pvt Ltd
      </p>

      <!-- end container -->
    </div>
  </nav>
</template>

<script>
export default {
  name: "FooterLayout",
  components: {},
};
</script>
